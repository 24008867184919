import axios from 'axios';
import '../css/style.css';
import React, { useState,useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link ,useNavigate  } from 'react-router-dom';

function Signup() {
  const navigate = useNavigate();
  const [isLogin, setIsLogin] = useState(true);
   useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/dashboard'); // Redirect to dashboard if already logged in
    }
  }, [navigate]);	
  const handleToggle = () => {
    setIsLogin(!isLogin);
  };

const handleSignup = async (e) => {
  e.preventDefault();
  const userName = document.getElementById('name-signup').value; // Renamed to userName
  const email = document.getElementById('email-signup').value;
  const password = document.getElementById('password-signup').value;

  try {
    const response = await axios.post('/api/signup', { name: userName, email, password });
    const { token, name } = response.data; // This 'name' is destructured from response.data
    localStorage.setItem('token', token); 
    localStorage.setItem('username', name); // This stores the name returned from the server
    window.location.href = '/dashboard'; // Redirect to dashboard after signup
  } catch (error) {
    console.error('Signup failed:', error);
    alert('Signup failed. Please try again.');
  }
};


  const handleLogin = async (e) => {
    e.preventDefault();
    const email = document.getElementById('email-login').value;
    const password = document.getElementById('password-login').value;

    try {
      const response = await axios.post('/api/login', { email, password });
       const { token, name } = response.data;
		console.log('Token:', token);
		console.log('Name:', name);
		localStorage.setItem('token', token); 
		localStorage.setItem('username', name);
      // Redirect to dashboard or another page
      window.location.href = '/dashboard';
    } catch (error) {
      console.error('Login failed:', error);
      alert('Login failed. Please check your credentials and try again.');
    }
  };

  return (
    <div>
      <Helmet>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        <title>Sign Up</title>
      </Helmet>

      <div style={{ backgroundColor: '#f6f4f1', backgroundImage: "url('../images/globe.png')", backgroundPosition: '10% 18rem', backgroundRepeat: 'no-repeat', height: '100vh' }}>
        <div className="container-fluid px-5 loginp" id="loginContainer" style={{ display: isLogin ? 'block' : 'none' }}>
          <div className="row justify-content-center">
            <div className="col-sm-4 shadow rounded px-3 py-4">
              <div className="row justify-content-center">
                <div className="col-4 pe-0">
                  <Link to="/">
                    <img src="../images/logo.png" style={{ width: '100%' }} alt="logo" />
                  </Link>
                </div>
              </div>
              <div className="col-sm-9 mt-4">
                <h2 className="form-title">Log In</h2>
              </div>
              <div className="col-sm-11 mt-2">
                <p>Welcome back! Please enter your credentials to access your account</p>
              </div>
              <form onSubmit={handleLogin} className="register-form">
                <div className="row mx-0 justify-content-center">
                  <div className="col-sm-12 mt-4 mb-1">
                    <label htmlFor="email-login">Your Email</label>
                    <div className="input-group mt-1">
                      <span className="input-group-text" style={{ backgroundColor: '#e2ddd2' }} id="basic-addon-name">
                        <span className="fas fa-envelope"></span>
                      </span>
                      <input type="email" className="form-control" style={{ backgroundColor: 'transparent' }} placeholder="example@company.com" id="email-login" required />
                    </div>
                  </div>
                  <div className="col-sm-12 mt-4 mb-1">
                    <label htmlFor="password-login">Your Password</label>
                    <div className="input-group mt-1">
                      <span className="input-group-text" style={{ backgroundColor: '#e2ddd2' }} id="basic-addon-name">
                        <span className="fas fa-key"></span>
                      </span>
                      <input type="password" placeholder="Password" className="form-control" style={{ backgroundColor: 'transparent' }} id="password-login" required />
                    </div>
                  </div>
                  <span className="fw-normal pt-3">
                    Not registered? <a href="#" className="fw-bold" onClick={handleToggle}>Create account</a>
                  </span>
                  <div className="col-sm-12 mt-4">
                    <button type="submit" className="btn-get-started scrollto mx-0 my-0">Log In</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="container-fluid px-5 loginp" id="registerContainer" style={{ display: isLogin ? 'none' : 'block' }}>
          <div className="row justify-content-center">
            <div className="col-sm-4 shadow rounded px-3 pt-4">
              <div className="row justify-content-center">
                <div className="col-4 pe-0">
                  <Link to="/">
                    <img src="../images/logo.png" style={{ width: '100%' }} alt="logo" />
                  </Link>
                </div>
              </div>
              <div className="col-sm-9 mt-4">
                <h2 className="form-title">Create an Account</h2>
              </div>
              <div className="col-sm-11 mt-2">
                <p>Welcome back! Please enter your credentials to access your account</p>
              </div>
              <form onSubmit={handleSignup} className="register-form">
                <div className="row mx-0 justify-content-center">
                  <div className="col-sm-12 mt-2 mb-1">
                    <label htmlFor="name-signup">Your Name</label>
                    <div className="input-group mt-1">
                      <span className="input-group-text" style={{ backgroundColor: '#e2ddd2' }} id="basic-addon-name">
                        <span className="fas fa-user"></span>
                      </span>
                      <input type="text" placeholder="Your Name" style={{ backgroundColor: 'transparent' }} className="form-control" id="name-signup" required />
                    </div>
                  </div>
                  <div className="col-sm-12 mt-4 mb-1">
                    <label htmlFor="email-signup">Your Email</label>
                    <div className="input-group mt-1">
                      <span className="input-group-text" style={{ backgroundColor: '#e2ddd2' }} id="basic-addon-name">
                        <span className="fas fa-envelope"></span>
                      </span>
                      <input type="email" placeholder="example@company.com" style={{ backgroundColor: 'transparent' }} className="form-control" id="email-signup" required />
                    </div>
                  </div>
                  <div className="col-sm-12 mt-4 mb-1">
                    <label htmlFor="password-signup">Your Password</label>
                    <div className="input-group mt-1">
                      <span className="input-group-text" style={{ backgroundColor: '#e2ddd2' }} id="basic-addon-name">
                        <span className="fas fa-key"></span>
                      </span>
                      <input type="password" placeholder="Password" className="form-control" style={{ backgroundColor: 'transparent' }} id="password-signup" required />
                    </div>
                  </div>
                  <span className="fw-normal pt-3">
                    Already have an account? <a href="#" className="fw-bold" onClick={handleToggle}>Log in</a>
                  </span>
                  <div className="col-sm-12 mt-4">
                    <button type="submit" className="btn-get-started scrollto mx-0 my-0">Sign Up</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Signup;
