import React, { useState, useEffect } from 'react';
import '../css/style.css'; 
import Signup from './Signup'
import Contact from './Contact'
import { Link } from 'react-router-dom';
const Header = ({ selectedSection, onSelectSection }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); 
  const sections = ['world news', 'politics', 'business', 'technology', 'sport', 'film', 'books', 'others'];
  const [weather, setWeather] = useState({ temp: '--°C', icon: 'fa-sun', sunrise: 'N/A', sunset: 'N/A' });
  const [date, setDate] = useState('');
  const token = localStorage.getItem('token');
  const username = localStorage.getItem('username');
 
  useEffect(() => {
    async function fetchWeather() {
      const apiKey = '0866a86a5ff611f45c5d11b2bdb1d269';
      const latitude = 49.2827;
      const longitude = -123.1207;
      const url = `https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&units=metric&appid=${apiKey}`;

      try {
        const response = await fetch(url);
        const data = await response.json();
        const temperature = data.main.temp.toFixed(0);
        const weatherCondition = data.weather[0].main.toLowerCase();
        const timezoneOffset = -7 * 3600; // Pacific Time (UTC-7) in seconds

        const sunriseTime = formatTime(data.sys.sunrise, timezoneOffset);
        const sunsetTime = formatTime(data.sys.sunset, timezoneOffset);

        setWeather({
          temp: `${temperature}°C`,
          icon: getWeatherIcon(weatherCondition),
          sunrise: sunriseTime,
          sunset: sunsetTime
        });
      } catch (error) {
        console.error('Error fetching weather data:', error);
      }
    }

    function formatTime(utcTimestamp, timezoneOffset) {
      const date = new Date((utcTimestamp + timezoneOffset) * 1000);
      const hours = date.getUTCHours();
      const minutes = date.getUTCMinutes();
      const period = hours >= 12 ? 'PM' : 'AM';
      const formattedHours = (hours % 12) || 12;
      const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
      return `${formattedHours}:${formattedMinutes} ${period}`;
    }

    function getWeatherIcon(condition) {
      switch (condition) {
        case 'clear':
          return 'fa-solid fa-sun';
        case 'clouds':
          return 'fa-solid fa-cloud';
        case 'rain':
          return 'fa-solid fa-cloud-rain';
        case 'snow':
          return 'fa-solid fa-snowflake';
        case 'thunderstorm':
          return 'fa-solid fa-bolt';
        case 'mist':
        case 'haze':
        case 'fog':
          return 'fa-solid fa-smog';
        default:
          return 'fa-solid fa-cloud';
      }
    }

    fetchWeather();

    // Set the date on mount
    function updateDate() {
      const now = new Date();
      const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      const dayName = dayNames[now.getDay()];
      const day = now.getDate();
      const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
      const monthName = monthNames[now.getMonth()];
      const year = now.getFullYear();
      const formattedDate = `${day}${getDaySuffix(day)} ${monthName} ${year}`;
      setDate(`<span class="font-weight-bold">${dayName}</span><br>${formattedDate}`);
    }

    function getDaySuffix(day) {
      if (day > 3 && day < 21) return 'th';
      switch (day % 10) {
        case 1: return "st";
        case 2: return "nd";
        case 3: return "rd";
        default: return "th";
      }
    }

    updateDate();
  }, []);
 const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  return (
    <header id="dheader" className="fixed-top">
      <div className="container-fluid px-0 px-md-5">
        <div className="px-5">
          <div className="row align-items-center" style={{ borderBottom: '2px solid black' }}>
            <div className="col-9 col-md-2 pe-0 logoclass">
              <Link to ="/">
			  <img src="./images/logo.png" style={{ width: '65%' }} alt="logo" />
			  </Link>	
			</div>
            <div className="col-3 col-md-3 ps-0 text-end">
              <span className="weather font-weight-bold d-none d-md-block">
                <span className="d-none d-md-inline-block me-1">Vancouver B.C </span>
                <i id="weather-icon" className={`weather-icon ${weather.icon}`}></i>
                <span id="temperature" className="ms-1">{weather.temp}</span>
              </span>
              <span id="sunrise-sunset-times" className="d-none d-md-block">
                <i className="fa-regular fa-sun"></i> {weather.sunrise}
                <i className="fa fa-moon ms-2"></i> {weather.sunset}
              </span>
				<i 
                className="fa fa-bars d-block d-md-none" 
                style={{ fontSize: '1.5rem', cursor: 'pointer' }} 
                aria-hidden="true"
                onClick={toggleSidebar} // Open sidebar on click
              ></i>
            </div>
            <div className="col-6 align-items-center justify-content-end d-none d-md-flex">
             <Link to="/contact" className="ct px-3">Contact Us</Link>
              {token ? (
			   <Link to="/dashboard" className="btn-get-started scrollto text-decoration-none">
				Hi, {username}
			  </Link>
			   ) : (
			  <Link to="/signup" className="btn-get-started scrollto text-decoration-none">
				Get Started
			  </Link>
			  )} 
            </div>
          </div>
        </div>
      </div>
	  
      {/* Sidebar */}
      <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
        <button className="close-btn" onClick={toggleSidebar}>×</button>
        <ul>
          <li>
            <Link to="/contact" onClick={toggleSidebar}>Contact Us</Link>
          </li>
          <li>
            {token ? (
              <Link to="/dashboard" onClick={toggleSidebar}>
                Hi, {username}
              </Link>
            ) : (
              <Link to="/signup" onClick={toggleSidebar}>Get Started</Link>
            )}
          </li>
        </ul>
      </div>
      <div className="container-fluid px-2 px-md-5">
        <div className="px-5">
          <div className="row align-items-center" style={{ borderBottom: '2px solid black' }}>
            <div className="col-6 col-md-2 px-0 datedis">
              <p id="date-display" className="mb-0" style={{ letterSpacing: '0.3px', fontSize: '1.05rem' }} dangerouslySetInnerHTML={{ __html: date }}></p>
            </div>
            <div className="col-6 col-md-2 d-block d-md-none px-0 text-end">
              <span className="weather1 font-weight-bold">
                <span className="d-none d-md-inline-block">Vancouver B.C</span>
                <i id="weather-icon1" className={`fa-regular ${weather.icon}`}></i>
                <span id="temperature1">{weather.temp}</span>
              </span><br />
              <span id="sunrise-sunset-times1">
                <i className="fa-regular fa-sun"></i> {weather.sunrise}
                <i className="fa fa-moon ms-2"></i> {weather.sunset}
              </span>
            </div>
            <div className="col-12 col-md-9">
              <div className="horizontal-scroll">
				  <ul className="news-types">
					{sections.map((section) => (
					  <li key={section}>
						<a
						  href={`#${section}`}
						  onClick={(e) => {
							e.preventDefault();
							onSelectSection(section);
						  }}
						  className={selectedSection === section ? 'selected' : ''}
						>
						  {section.charAt(0).toUpperCase() + section.slice(1)}
						</a>
					  </li>
					))}
				  </ul>
				</div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
