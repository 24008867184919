import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import '../css/style.css'; // Add any additional CSS
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';

function News({ selectedSection }) {
  const token = localStorage.getItem('token');
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedArticleUrl, setSelectedArticleUrl] = useState('');
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [imagesLoaded, setImagesLoaded] = useState(0); // Track loaded images
  const masonryRef = useRef(null);

  useEffect(() => {
    let intervalId;

    async function fetchArticles() {
      try {
        const response = await axios.get('/api/articles');
        const newArticles = response.data.map(article => ({
          id: article.weburl,
          sectionname: article.sectionname,
          webtitle: article.webtitle,
          weburl: article.weburl,
          imageurl: article.imageurl || './images/fallback.jpg',
          webpublicationdate: article.webpublicationdate,
          bodytext: article.bodytext,
          summary: article.summary,
          main: article.main
        }));

        setArticles(prevArticles => {
          const existingArticles = new Map(prevArticles.map(article => [article.id, article]));

          newArticles.forEach(article => {
            if (!existingArticles.has(article.id)) {
              existingArticles.set(article.id, article);
            }
          });

          const updatedArticles = Array.from(existingArticles.values());
          updatedArticles.sort((a, b) => new Date(b.webpublicationdate) - new Date(a.webpublicationdate));

          return updatedArticles;
        });

        setLoading(false);
      } catch (err) {
        setError('Failed to fetch articles.');
        setLoading(false);
      }
    }

    fetchArticles();

    intervalId = setInterval(fetchArticles, 1200000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const updateHeaderHeight = () => {
    const header = document.getElementById('dheader');
    if (header) {
      const headerHeight = header.offsetHeight;
      const extraHeight = 2 * parseFloat(getComputedStyle(document.documentElement).fontSize);
      const totalHeight = headerHeight + extraHeight + 'px';
      document.documentElement.style.setProperty('--header-height', totalHeight);
    }
  };

useEffect(() => {
  // Function to handle resize event and reinitialize Masonry
  const handleResize = () => {
    updateHeaderHeight(); // Update header height
    initializeMasonry(); // Reinitialize Masonry layout
  };

  // Call handleResize when the component mounts to initialize correctly
  handleResize();

  // Add event listener for window resize
  window.addEventListener('resize', handleResize);

  // Cleanup: Remove the event listener when the component unmounts
  return () => {
    window.removeEventListener('resize', handleResize);
  };
}, []);
useEffect(() => {
  if (selectedSection === 'others') {
    setFilteredArticles(articles.filter(article => !['world', 'politics', 'business', 'technology', 'sport', 'film', 'books'].includes(article.sectionname.toLowerCase())));	 
  } else if (selectedSection) {
    setFilteredArticles(articles.filter(article => article.sectionname.toLowerCase() === selectedSection));
  } else {
    setFilteredArticles(articles);
  }

  // Reset the image load count whenever filtering happens
  setImagesLoaded(0);
}, [selectedSection, articles]);

  // Initialize Masonry
  const initializeMasonry = () => {
    if (masonryRef.current && imagesLoaded === filteredArticles.length) {
      const Masonry = window.Masonry;
      new Masonry(masonryRef.current, {
        itemSelector: '.col-12',
        columnWidth: '.col-12',
        percentPosition: true,
      });
    }
  };
const handleImageLoad = () => {
  setImagesLoaded(prev => prev + 1);
};

// Reinitialize Masonry when images are loaded or filteredArticles change
useEffect(() => {
  if (masonryRef.current && imagesLoaded === filteredArticles.length) {
    const Masonry = window.Masonry;
    const msnry = new Masonry(masonryRef.current, {
      itemSelector: '.col-12',
      columnWidth: '.col-12',
      percentPosition: true,
    });

    msnry.layout(); // Force layout recalculation after filtering
  }
}, [imagesLoaded, filteredArticles]);

  const handleBookmarkClick = async (webUrl) => {
    if (!token) {
      setSelectedArticleUrl(webUrl);
      setShowModal(true);
      return;
    }

    try {
      const response = await axios.get('https://newspulse.nav.ninja/api/user', {
        headers: { Authorization: `Bearer ${token}` },
      });
      const user = response.data;

      await axios.post(
        'https://newspulse.nav.ninja/api/bookmark',
        {
          email: user.email,
          webUrl: webUrl,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      alert('Article bookmarked successfully!');
    } catch (error) {
      alert('Failed to bookmark the article.');
    }
  };

  const handleLogin = () => {
    window.location.href = '/signup';
  };

  const handleCloseModal = () => setShowModal(false);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <>
      <Helmet>
        <script src="https://cdn.jsdelivr.net/npm/masonry-layout@4.2.2/dist/masonry.pkgd.min.js"
                integrity="sha384-GNFwBvfVxBkLMJpYMOABq3c+d3KnQxudP/mGPkzpZSTYykLBNsZEnG2D9G/X/+7D"
                crossOrigin="anonymous"
                async>
        </script>
      </Helmet>
      <div className="container-fluid px-5 paper">
        <div className="px-0 px-md-5 pt-5 pt-md-0">
          <div className="row" ref={masonryRef}>
            {filteredArticles.map((article, index) => (
              <div
                key={index}
                className={`col-12 ${index === 1 ? 'col-xl-6' : 'col-xl-3'} px-3 mb-4`}
              >
                <div className="news py-4 px-2">
                  <center style={{ marginTop: '-35px' }}>
                    <span className="newtag px-3 py-1">{article.sectionname}</span>
                  </center>
                  <img
                    src={article.imageurl}
                    alt={article.webtitle}
                    onError={(e) => { e.target.src = './images/fallback.jpg'; }}
                    onLoad={handleImageLoad} // Increment loaded images count
                    style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
                    className="mt-2"
                  />
                  <div className="d-flex justify-content-between px-1 pt-1 pb-3">
                    <span className="newhead">The Guardian</span>
                    <span className="newhead">{new Date(article.webpublicationdate).toLocaleTimeString()}</span>
                  </div>
                  <h6 className="pb-2">{article.webtitle}</h6>
                  <p>{article.summary}</p>
                  <div className="row px-2 justify-content-between">
                    <div className="col-4">
                      <Link to="/articledetail" state={{ article, similarArticles: filteredArticles.slice(0, 5) }}>
                        Read More
                      </Link>
                    </div>
                    <div className="col-4 text-end">
                      <i
                        className="fas fa-bookmark"
                        style={{ fontSize: '1.3rem', cursor: 'pointer' }}
                        onClick={() => handleBookmarkClick(article.weburl)}
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Login Required</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You need to log in to bookmark articles.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button style={{ backgroundColor: 'black' }} onClick={handleLogin}>
            Login
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default News;
