// Homepage.js
import React, { useState } from 'react';
import Header from './Header';
import News from './News';
import { Helmet } from 'react-helmet';
function Homepage() {
  const [selectedSection, setSelectedSection] = useState('');

  const handleSelectSection = (section) => {
    if (selectedSection === section) {
      // If the selected section is clicked again, clear the filter
      setSelectedSection('');
    } else {
      setSelectedSection(section);
    }
  };

  return (
    <div>
	<Helmet>
		<meta charset="UTF-8"/>
	  <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      
        <title>NewsPulse</title>
      </Helmet>
      <Header selectedSection={selectedSection} onSelectSection={handleSelectSection} />
      <News selectedSection={selectedSection} />
    </div>
  );
}

export default Homepage;
