import React from 'react';
import Header from './Header';
import Signup from './Signup'
import { Helmet } from 'react-helmet';
import ContactDetails from './ContactDetails'
function Contact() {
  return (
   
    <div>
	<Helmet>
		<meta charset="UTF-8"/>
		<meta name="viewport" content="width=device-width, initial-scale=1.0"/>
		<title>Contact Us</title>
   </Helmet>
      <Header />
      <ContactDetails />
    </div>
  );
}

export default Contact;
