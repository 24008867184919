import React, { useState, useEffect } from 'react';
import '../css/style.css'; // Add any additional CSS
import { Helmet } from 'react-helmet';
function ContactDetails() {
  return (
    <>
     

      <div className="container-fluid px-5 paper">
        <div className="row justify-content-center">
          <div className="col-sm-5">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d83153.91322372097!2d-123.28902543303465!3d49.36047996586155!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54866e153fed7cd3%3A0x468dd2eb0790eb95!2sWest%20Vancouver%2C%20BC!5e0!3m2!1sen!2sca!4v1716190444394!5m2!1sen!2sca"
              style={{ width: '100%', height: '90%', border: '0' }}
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Google Maps"
            ></iframe>
          </div>
          <div className="col-sm-4 news px-3 py-4">
            <center style={{ marginTop: '-35px' }} className="pb-3">
              <span className="trending px-3 py-1">CONTACT US</span>
            </center>
            <p className="px-2">
              We’d love to hear from you! Whether you have a question, feedback, or need assistance, feel free to reach out to us through any of the following methods
            </p>
            <form method="POST" className="register-form" id="register-form">
              <div className="row mx-0 justify-content-center">
                <div className="col-sm-10 mt-2 mb-1">
                  <label htmlFor="name">Your Name</label>
                  <div className="input-group mt-1">
                    <span className="input-group-text" style={{ backgroundColor: '#e2ddd2' }} id="basic-addon-name">
                      <span className="fas fa-user"></span>
                    </span>
                    <input
                      type="text"
                      placeholder="Your Name"
                      style={{ backgroundColor: 'transparent' }}
                      className="form-control"
                      id="name"
                      required
                    />
                  </div>
                </div>
                <div className="col-sm-10 mt-4 mb-1">
                  <label htmlFor="email">Your Email</label>
                  <div className="input-group mt-1">
                    <span className="input-group-text" style={{ backgroundColor: '#e2ddd2' }} id="basic-addon-email">
                      <span className="fas fa-envelope"></span>
                    </span>
                    <input
                      type="email"
                      className="form-control"
                      style={{ backgroundColor: 'transparent' }}
                      placeholder="example@company.com"
                      id="email"
                      required
                    />
                  </div>
                </div>
                <div className="col-sm-10 mt-4">
                  <div className="form_row_full">
                    <textarea
                      className="form_textarea_full2 px-3"
                      placeholder="Write Your Query..."
                    ></textarea>
                  </div>
                </div>
                <div className="col-sm-9 mt-1 px-0">
                  <button type="submit" className="btn-get-started scrollto mx-0 my-0">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactDetails;