import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import axios from 'axios';
const ArticleDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [comment, setComment] = useState('');
  const [comments, setComments] = useState([]);
  const [userNames, setUserNames] = useState({}); // State to hold user names
  const [showModal, setShowModal] = useState(false);
  // Check if state exists
  const { article, similarArticles } = location.state || {};
  const articleUrl = location.state?.article?.weburl;
  useEffect(() => {
    window.scrollTo(0, 0);
	  
   if (article) {
      fetchComments(); // Fetch comments on component mount
    }
  }, [article]);
  if (!article) {
    // If there's no article data in the state, display an error message and a button to go back.
    return (
      <div>
        <h2>Article not found</h2>
        <button onClick={() => navigate('/')}>Go Back to Homepage</button>
      </div>
    );
  }
  const processMediaContent = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const figures = doc.querySelectorAll('figure');

    figures.forEach(figure => {
      // Check if the figure contains a gu-atom element
      const guAtom = figure.querySelector('gu-atom');
      if (guAtom) {
        // Replace gu-atom with its contents
        const iframe = guAtom.querySelector('iframe');
        if (iframe) {
          iframe.style.width = '100%';
          iframe.style.height = '100%';
          guAtom.replaceWith(iframe);
        }
      }

      // Apply styles for image figures
      if (figure.classList.contains('element-image')) {
        const img = figure.querySelector('img');
        if (img) {
          img.style.width = '100%';
          img.style.height = '100%';
        }
      } else if (figure.classList.contains('element-atom')) {
        // Apply styles for iframe figures
        const iframe = figure.querySelector('iframe');
        if (iframe) {
          iframe.style.width = '100%';
          iframe.style.height = '100%';
        }
        figure.style.width = '100%';
        figure.style.height = '100%';
      }
    });

    return doc.body.innerHTML;
  };

  const mediaContent = processMediaContent(article.main || '');
 const sentences = article.bodytext.split('.').filter(sentence => sentence.trim().length > 0);
  
  // Define the number of paragraphs
  const paragraphsCount = 4; // Adjust this based on your requirement
  const paragraphSize = Math.ceil(sentences.length / paragraphsCount);

  // Group sentences into paragraphs
  const paragraphs = [];
  for (let i = 0; i < sentences.length; i += paragraphSize) {
    const paragraph = sentences.slice(i, i + paragraphSize).join('. ') + '.';
    paragraphs.push(paragraph);
  }


const fetchComments = async () => {
    try {
      const response = await axios.get(`http://localhost:3001/api/comments?webUrl=${articleUrl}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setComments(response.data);

      // Fetch user names for all comments
      const emailList = response.data.map(comment => comment.email);
      const userNamePromises = emailList.map(email => fetchUserName(email));
      const userNamesArray = await Promise.all(userNamePromises);
      const userNamesMap = emailList.reduce((acc, email, index) => {
        acc[email] = userNamesArray[index];
        return acc;
      }, {});
      setUserNames(userNamesMap);
      
    } catch (error) {
      console.error('Error fetching comments:', error);
    }
  };
const fetchUserName = async (email) => {
  try {
    const response = await axios.get(`http://localhost:3001/api/user/name`, {
      params: { email },
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    });
    return response.data.name;
  } catch (error) {
    console.error('Error fetching user name:', error);
    return 'Unknown'; // Fallback in case of error
  }
};
  const handleCommentSubmit = async () => {
    try {
      const token = localStorage.getItem('token');
       if (!token) {
        setShowModal(true);
        return;
      }


      const cred = await axios.get('http://localhost:3001/api/user', {
        headers: { Authorization: `Bearer ${token}` },
      });

      const user = cred.data;
      const email = user.email;

      await axios.post('http://localhost:3001/api/addcomment', {
        email,
        webUrl: articleUrl,
        comment,
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setComment('');
      fetchComments();
    } catch (error) {
      console.error('Error submitting comment:', error);
      if (error.response && error.response.status === 401) {
       setShowModal(true);
        setShowModal(true);
      }
    }
  };

  const handleLogin = () => {
    setShowModal(false);
    navigate('/signup');
  };

  const handleCancel = () => {
    setShowModal(false);
  };

const LoginModal = ({ show, onClose, onLogin }) => {
  if (!show) return null;

  return (
    <div className="modal" style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Login Required</h5>
            <button type="button" className="btn-close" onClick={onClose}></button>
          </div>
          <div className="modal-body">
            <p>You need to log in to submit a comment. Please log in to continue.</p>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-danger" onClick={onClose}>
              Cancel
            </button>
            <button type="button" className="btn" style={{backgroundColor:'black'}} onClick={onLogin}>
              Log In
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const formatDate = (dateString) => {
  const date = new Date(dateString);

  // Convert to Pacific Time
  const pacificOffset = -7; // Pacific Time is UTC-7 in daylight saving time
  const pacificDate = new Date(date.getTime() + pacificOffset * 60 * 60 * 1000);

  // Get the day, month, and year
  const day = pacificDate.getUTCDate();
  const month = pacificDate.toLocaleString('default', { month: 'short' }); // Jan, Feb, Mar, etc.

  // Get the hours and minutes
  const hours = pacificDate.getUTCHours().toString().padStart(2, '0');
  const minutes = pacificDate.getUTCMinutes().toString().padStart(2, '0');

  return `${day} ${month} ${hours}:${minutes}`;
};
  return (
  <div>
  	  <LoginModal show={showModal} onClose={handleCancel} onLogin={handleLogin} />
  <Helmet>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        <title>{article.webtitle}</title>
      </Helmet>

    <div className="container-fluid px-4 px-md-5 py-5">
      <div className="row px-0 px-md-5 justify-content-center">
        {/* Trending Section */}
        <div className="col-sm-2 d-none d-md-block px-3 py-4 my-5">
          
        </div>
        
        {/* Main Article Section */}
        <div className="col-sm-8 px-4">
         <Link to ="/">
		 Go Back
		 </Link>
          <h2 className="py-1">{article.webtitle}</h2>
           <div
            style={{ width: '100%', height: '58vh', overflow: 'hidden' }}
           dangerouslySetInnerHTML={{ __html: mediaContent || '<img src="../images/img3.jpg" style={{ width: "100%", height: "100%", objectFit: "cover" }} />' }}
          />
          <div className="d-flex justify-content-between px-1 pt-1 pb-3">
            <span className="newhead">The Gaurdian</span>
            <span className="newhead">{new Date(article.webpublicationdate).toLocaleTimeString()}</span>
          </div>
          {paragraphs.map((para, index) => (
            <p key={index}>{para}</p>
          ))}
        </div>
        
        {/* Similar Articles Section */}
        <div className="col-sm-2 onlynew px-3 py-4 my-5">
          <center style={{ marginTop: '-35px' }} className="pb-3">
            <span className="trending px-3 py-1">TRENDING</span>
          </center>
          {/* Placeholder for similar articles */}
          {similarArticles && similarArticles.map((similarArticle, index) => (
          <h6 key={index} className="pt-2">
           
			<Link to="/articledetail" state={{ article: similarArticle, similarArticles: similarArticles }} style={{color:'black'}}>
              {similarArticle.webtitle}
            </Link>
          </h6>
        ))}
        </div>
        
       {/* Comments Section */}
<div className="col-sm-10 onlynew px-3 py-4 my-5">
  <center style={{ marginTop: '-35px' }}>
    <span className="trending px-3 py-1">COMMENTS</span>
  </center>
  <div className="row justify-content-center mt-5">
   <div className="col-12 col-md-9">
  <div className="form_row_full d-flex align-items-center">
    <textarea
      className="form_textarea_full"
      placeholder="Write Your Comments..."
      value={comment}
      onChange={(e) => setComment(e.target.value)}
      style={{ flex: '1', marginRight: '10px' }} // Adjusts textarea size and spacing
    ></textarea>
    <button className="reset-btn d-none d-lg-block" onClick={handleCommentSubmit}>
      Submit
    </button>
  </div>
  <button className="reset-btn d-block d-lg-none w-100" onClick={handleCommentSubmit}>
      Submit
    </button>
</div>
  </div>
  {/* Display fetched comments */}
  {comments.map((comment, index) => (
    <div key={index} className="row justify-content-start py-2">
      <div className="col-3 col-lg-2 pt-3 text-end">
        <span className="circle">{userNames[comment.email]?.charAt(0) || 'U'}</span>
      </div>
      <div className="col-7 col-lg-7 px-1" style={{ paddingTop: '0.8rem' }}>
        <p className="mb-1">
          <strong style={{ fontSize: '1.15rem', letterSpacing: '0.2px' }}>
            {userNames[comment.email] || 'Unknown'}
          </strong>
        </p>
        <p>{comment.comment}</p>
      </div>
      <div className="col-2 px-1" style={{ paddingTop: '0.8rem' }}>
        <p className="mb-1">
          <strong style={{ fontSize: '1.1rem', letterSpacing: '0.2px' }}>
            {formatDate(comment.postedon)}
          </strong>
        </p>
      </div>
    </div>
  ))}

        </div>
      </div>
    </div>
	</div>
  );
};

export default ArticleDetail;
