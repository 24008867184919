import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios'; // Ensure axios is installed for making HTTP requests
import '../css/style.css'; 
import { Helmet } from 'react-helmet';


function Dashboard() {
  const [user, setUser] = useState(null);
  const [error, setError] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [articleToDelete, setArticleToDelete] = useState(null);
  const [bookmarkedArticles, setBookmarkedArticles] = useState([]);
  const [email, setEmail] = useState('');
  const [prevPassword, setPrevPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const navigate = useNavigate();
  const [showEmailSuccessModal, setShowEmailSuccessModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const token = localStorage.getItem('token');	
  const [userEmail, setUserEmail] = useState('');
const fetchUserData = async () => {
	 const token = localStorage.getItem('token');
	  if (!token) {
		navigate('/'); // Redirect to login if no token is found
		return;
	  }
      try {
        const response = await axios.get('https://newspulse.nav.ninja/api/user', {
          headers: { Authorization: `Bearer ${token}` },
        });
        const user = response.data;

        if (user && user.name) {
          setUser(user);
          localStorage.setItem('username', user.name);
          setUserEmail(user.email); 

          // Fetch bookmarks after user data is available
          fetchBookmarks(user.email);
        } else {
          console.error('User data is incomplete:', user);
          localStorage.removeItem('username'); // Clear username if user data is invalid
        }
      } catch (err) {
        console.error('Error fetching user data:', err.response ? err.response.data : err.message);
			setError('Failed to fetch user data.');
      }
    };

  useEffect(() => {
    // Check if user is authenticated
    if (!token) {
      navigate('/'); // Redirect to login if no token is found
      return;
    }

    // Fetch user data
    
    // Check if userEmail is already set; if not, fetch user data
    if (!userEmail) {
      fetchUserData();
    }
  }, [navigate, token, userEmail]);

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();

    // Step 1: Check if the new password and confirm password match
    if (newPassword !== confirmPassword) {
      alert("New password and confirm password do not match.");
      return;
    }

    try {
      // Step 2: Call the reset-password API
      const response = await axios.post('http://localhost:3001/api/reset-password', {
        email: userEmail,
        prevPassword,
        newPassword,
      });

      // Step 3: Handle the API response
      if (response.data.success) {
        // If the password was reset successfully, show the success modal
        setShowModal(true);
      } else if (response.data.message === 'Incorrect old password') {
        // If the old password is incorrect, alert the user
        alert('Old password is incorrect. Please try again.');
      } else {
        // Handle other possible errors
        alert('Error resetting password. Please try again.');
      }
    } catch (error) {
      // Handle any errors during the API call
      console.error('Error during password reset:', error);
      alert('An error occurred. Please try again.');
    }
  };

  const EmailSuccessModal = ({ show, onClose }) => {
    if (!show) return null;

    return (
      <div className="modal" style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Email Successfully Changed</h5>
              <button type="button" className="btn-close" onClick={onClose}></button>
            </div>
            <div className="modal-body">
              <p>Your email has been updated successfully.</p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn" style={{backgroundColor:'black', color:'white'}} onClick={onClose}>
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

const handleEmailSubmit = async (e) => {
  e.preventDefault();

  try {
    // API request to update the email
    const response = await axios.post('http://localhost:3001/api/update-email', {
      oldEmail: userEmail,
      newEmail: email,
    });

    if (response.data.success) {
      // Store the new token
     setShowEmailSuccessModal(true);
      localStorage.setItem('token', response.data.token);
      console.log('New Token:', localStorage.getItem('token')); // Check if the new token is stored
     
      // Optionally update the UI and re-fetch user data
      setUserEmail(email);
      await fetchUserData(); // Ensure fetchUserData uses the new token
    } else {
      alert('Failed to update email: ' + response.data.message);
    }
  } catch (error) {
    console.error('Error updating email:', error);
    alert('An error occurred. Please try again.');
  }
};

  const handleCloseModal = () => {
    setShowModal(false);
  };
	
  const fetchBookmarks = async (email) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.get(`https://newspulse.nav.ninja/api/viewbook?email=${email}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    const bookmarks = response.data; // Assuming the response is an array of bookmarks
	
    // Fetch articles based on the bookmarked URLs
    const articlePromises = bookmarks.map((bookmark) =>
      axios.get(`https://newspulse.nav.ninja/api/bookarticles?weburl=${bookmark.weburl}`)
    );

    const articleResponses = await Promise.all(articlePromises);
    const articles = articleResponses.map((response) => response.data);
	
    setBookmarkedArticles(articles);
	console.log(articles);
  } catch (err) {
    setError('Failed to fetch bookmarks.');
    console.error(err);
  }
};
const DeleteModal = ({ show, onClose, onDelete }) => {
  if (!show) return null;

  return (
    <div className="modal" style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Confirm Delete</h5>
            <button type="button" className="btn-close" onClick={onClose}></button>
          </div>
          <div className="modal-body">
            <p>Are you sure you want to remove this bookmark?</p>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={onClose}>
              Cancel
            </button>
            <button type="button" className="btn btn-danger" onClick={onDelete}>
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
// Function to show the delete modal
const handleShowDeleteModal = (article) => {
  setArticleToDelete(article);
  setShowDeleteModal(true);
};

// Function to close the delete modal
const handleCloseDeleteModal = () => {
  setShowDeleteModal(false);
  setArticleToDelete(null);
};

// Function to delete the bookmark
const handleDeleteBookmark = async () => {
  if (!articleToDelete) return;

  try {
    const token = localStorage.getItem('token');
    await axios.delete('http://localhost:3001/api/deletebookmark', {
      headers: { Authorization: `Bearer ${token}` },
      data: {
        email: user.email,
        weburl: articleToDelete.weburl,
      },
    });

    // Filter out the deleted article from the state
    setBookmarkedArticles((prevArticles) =>
      prevArticles.filter((article) => article.weburl !== articleToDelete.weburl)
    );

    handleCloseDeleteModal(); // Close the modal after deletion
  } catch (err) {
    setError('Failed to delete the bookmark.');
    console.error(err);
  }
};

    function updateHeaderHeight() {
      const header = document.getElementById('dheader1');
      if (header) {
        const headerHeight = header.offsetHeight;
        const extraHeight = 1.5 * parseFloat(getComputedStyle(document.documentElement).fontSize); // 1rem in pixels
        const totalHeight = headerHeight + extraHeight + 'px';
        document.documentElement.style.setProperty('--header-height', totalHeight);
      }
    }

    // Update on load
    updateHeaderHeight();
    console.log("USE EFFECT CALLED");
    // Update on window resize
    window.addEventListener('resize', updateHeaderHeight);
  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    navigate('/'); // Redirect to login on logout
  };

  return (
    <div>
	 <DeleteModal
      show={showDeleteModal}
      onClose={handleCloseDeleteModal}
      onDelete={handleDeleteBookmark}
    />
	  <EmailSuccessModal show={showEmailSuccessModal} onClose={() => setShowEmailSuccessModal(false)} />
	      {showModal && (
        <div className="modal" style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Password Successfully Changed</h5>
                <button type="button" className="btn-close" onClick={handleCloseModal}></button>
              </div>
              <div className="modal-body">
                <p>Your password has been updated successfully.</p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn" style={{backgroundColor:'black', color:'white'}} onClick={handleCloseModal}>
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Dashboard</title>
      </Helmet>
     <header id="dheader1" className="fixed-top" style={{ borderBottom: '2px solid black' }}>
      <div className="container-fluid px-5">
        <div className="px-5">
          <div className="row align-items-center" >
            <div className="col-9 col-md-2 pe-0 logoclass">
              <Link to ="/">
			  <img src="./images/logo.png" style={{ width: '65%' }} alt="logo" />
			  </Link>	
			</div>

            <div className="col-6 col-md-9 align-items-center justify-content-end d-none d-md-flex">
           
               <button onClick={handleLogout} className="btn btn-danger px-5 py-2">
              Log Out
            </button>
            </div>
          </div>
        </div>
      </div>

    
    </header>
	  <div className="container-fluid" style={{backgroundColor:'white'}}>
	  <div className="container dboard">
        {user ? (
          <div>
            <h5>Welcome, {user.name}!</h5>
            <div className="row my-4 px-2 justify-content-center">
      <div className="col-lg-6 col-sm-12 ps-lg-3">
        <form
          id="email-form"
          style={{ height: '445px',backgroundColor:'#f5f5f3' }}
          className="px-5 py-4 shadow rounded"
          onSubmit={handleEmailSubmit}

        >
          <h2>Change Email</h2>
          <p>
            Securely update your account's email address by entering the new email you'd like to use
            for communication and account-related notifications.
          </p>
          <label htmlFor="newem">New Email</label>
          <div className="input-group col-sm-8 px-0 mb-4">
            <span className="input-group-text" id="basic-addon-name">
              <span className="fas fa-envelope"></span>
            </span>
            <input
              id="newem"
              type="email"
              className="form-control"
              placeholder="Enter Your New Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="col-sm-4 px-0 mb-4">
            <button type="submit" className="reset-btn">
              Reset
            </button>
          </div>
        </form>
      </div>

      <div className="col-lg-6 col-sm-12 mt-4 mt-lg-0 pe-lg-3">
        <form
        id="password-form"
        style={{ height: '445px', backgroundColor: '#f5f5f3' }}
        className="px-5 py-4 shadow rounded"
        onSubmit={handlePasswordSubmit}
      >
        <h2>Reset Password</h2>
        <p>
          Please enter your current password and choose a new password to reset your account
          credentials.
        </p>
        <label htmlFor="prevp">Previous Password</label>
        <div className="input-group col-sm-8 px-0 mb-4">
          <span className="input-group-text" id="basic-addon-name">
            <span className="fas fa-key"></span>
          </span>
          <input
            id="prevp"
            type="password"
            className="form-control"
            placeholder="Enter Your Previous Password"
            value={prevPassword}
            onChange={(e) => setPrevPassword(e.target.value)}
            required
          />
        </div>
        <label htmlFor="newp">New Password</label>
        <div className="input-group col-sm-8 px-0 mb-4">
          <span className="input-group-text" id="basic-addon-name">
            <span className="fas fa-key"></span>
          </span>
          <input
            id="newp"
            type="password"
            className="form-control"
            placeholder="Enter Your New Password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
        </div>
        <label htmlFor="confirmp">Confirm Password</label>
        <div className="input-group col-sm-8 px-0 mb-4">
          <span className="input-group-text" id="basic-addon-name">
            <span className="fas fa-key"></span>
          </span>
          <input
            id="confirmp"
            type="password"
            className="form-control"
            placeholder="Confirm Your New Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </div>
        <div className="col-sm-4 px-0 mb-4">
          <button type="submit" className="reset-btn">
            Reset
          </button>
        </div>
      </form>
      </div>
    </div>
            {bookmarkedArticles.length > 0 && <h4>Your Bookmarks</h4>}
            <div className="row pt-4" data-masonry='{"percentPosition": true }'>
              {bookmarkedArticles.map((article, index) => (
                <div key={index} className="col-12 col-lg-6 col-xl-4 px-3 mb-4">
                  <div className="news py-4 px-2" style={{backgroundColor:'#f5f5f3'}}>
                    <center style={{ marginTop: '-35px' }}>
                      <span className="newtag px-3 py-1">{article.sectionname}</span>
                    </center>
                    <img 
                      src={article.imageurl} 
                      alt={article.webtitle} 
                      style={{ width: '100%', height: 'auto', objectFit: 'cover' }} 
                      className="mt-2" 
                    />
                    <div className="d-flex justify-content-between px-1 pt-1 pb-3">
                      <span className="newhead">The Guardian</span>
                      <span className="newhead">{new Date(article.webpublicationdate).toLocaleTimeString()}</span>
                    </div>
                    <h6 className="pb-2">{article.webtitle}</h6>
                    <p>{article.summary}</p>
                    <div className="row px-2 justify-content-between">
                      <div className="col-4">
                        <Link to="/articledetail" state={{ article }}>
                          Read More
                        </Link>
                      </div>  
                      <div className="col-4 text-end">
                         <i
                          className="fa fa-trash"
                          style={{ fontSize: '1.3rem', cursor: 'pointer' }}
                          onClick={() => handleShowDeleteModal(article)}
                        ></i>
                      </div>  
                    </div>  
                  </div>
                </div>
              ))}
            </div>
			<div className="col-12 align-items-center justify-content-end d-block d-md-none">
           
               <button onClick={handleLogout} className="btn btn-danger w-100 px-5 py-2">
              Log Out
            </button>
            </div>
          </div>
        ) : (
          <p>Loading...</p>
        )}
      </div>
    </div>
	 </div>
  );
}

export default Dashboard;
